import { Box, Divider, ListItem, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { FC, ReactNode } from 'react'

interface Props {
  iconComponent?: OverridableComponent<SvgIconTypeMap>
  iconColor?: string
  icon?: ReactNode
  title: string
  message: ReactNode
  isLastItem?: boolean
  isFirstItem?: boolean
  onClick?: (title: string) => void
}

export const LogListItem: FC<Props> = (props) => {
  return (
    <ListItem button style={{ padding: 0 }} onClick={() => props.onClick?.(props.title)}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" width={24}>
          <Box display="flex" justifyContent="center" height={8}>
            {!props.isFirstItem && <Divider orientation="vertical" style={{ borderColor: 'gray' }} />}
          </Box>
          <Box display="flex" justifyContent="center" my="2px">
            {props.icon}
            {props.iconComponent && props.iconColor && (
              <props.iconComponent style={{ color: props.iconColor, fontSize: 14 }} />
            )}
          </Box>
          {!props.isLastItem && (
            <Box display="flex" justifyContent="center" flex={1}>
              <Divider orientation="vertical" style={{ borderColor: 'gray' }} />
            </Box>
          )}
        </Box>
        <Box ml={1} py={1}>
          <Typography style={{ fontSize: 12, fontWeight: 700 }}>{props.title}</Typography>
          <Typography style={{ fontSize: 12, fontWeight: 500, opacity: 0.75 }}>{props.message}</Typography>
        </Box>
      </Box>
    </ListItem>
  )
}
