import { Stack, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { FC } from 'react'
import { translateWeekday } from '../helpers/translateWeekday'

interface Props {
  isFullyCovered: boolean
  notCoveredWeekdays?: {
    weekday: number
    notCovered: { from: string; to: string }[]
  }[]
  fontSize?: string
}

export const WarehouseCoverageTable: FC<Props> = (props) => {
  return (
    <StyledTableContainer>
      <Table aria-label="simple table" size="small">
        <TableBody>
          {props.notCoveredWeekdays?.map((row) => (
            <TableRow key={row.weekday} sx={{ verticalAlign: 'Top' }}>
              <TableCell component="th" scope="row">
                <Typography
                  color={!!row.notCovered?.length ? 'error' : 'inherit'}
                  fontWeight={!!row.notCovered?.length ? 700 : 500}
                  fontSize={props.fontSize}
                >
                  {translateWeekday(row.weekday)}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {row.notCovered?.length ? (
                  <Stack color="error.main">
                    <Typography fontWeight={700} fontSize={props.fontSize}>
                      Not Covered At:
                    </Typography>

                    {row.notCovered?.map((timeGap) => {
                      return (
                        <Typography
                          key={timeGap.from}
                          fontSize={props.fontSize}
                        >{`• ${timeGap.from} - ${timeGap.to}`}</Typography>
                      )
                    })}
                  </Stack>
                ) : (
                  <Typography color="success.main" fontSize={props.fontSize}>
                    Fully Covered
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled(TableContainer)(() => {
  return {
    border: 'solid rgba(224, 224, 224, 1) 1px',
    borderRadius: '8px',
    padding: '0px',
    backgroundColor: 'white',
  }
})
