import { Button, Checkbox, CircularProgress, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import { isOpeningHours, Warehouse } from '@quickcommerceltd/zone'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import toast from 'react-hot-toast'
import * as yup from 'yup'
import { updateWarehouseOpeningHoursCallable } from '../callables/updateWarehouseOpeningHours'

const validationSchema = yup.object().shape({
  openingHours: yup.object().shape({
    from: yup.string().required(),
    to: yup.string().required(),
  }),
})

type FormData = yup.InferType<typeof validationSchema>

interface Props {
  warehouse: Warehouse
  disabled?: boolean
}

export const OpeningHoursForm: FC<Props> = (props) => {
  const [isFullDayChecked, setIsFullDayChecked] = useState(
    props.warehouse.openingHours?.from === '00:00' && props.warehouse.openingHours?.to === '24:00'
  )

  const { values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue } = useFormik<FormData>({
    validationSchema,
    initialValues: {
      openingHours: {
        from: props.warehouse.openingHours?.from ?? '00:00',
        to: props.warehouse.openingHours?.to ?? '24:00',
      },
    } as FormData,
    enableReinitialize: true,
    onSubmit: async (values: FormData) => {
      try {
        if (!isOpeningHours(values.openingHours)) {
          return toast.error(
            "Opening Hours invalid. Check if times start with zero e.g. '06:00' and if from is set before to!"
          )
        }

        await updateWarehouseOpeningHoursCallable({
          warehouseId: props.warehouse.id,
          openingHours: values.openingHours,
        })

        toast.success('Update was successful')
      } catch (error: any) {
        alert(error.message)
      }
    },
  })

  const handleCheckboxChange = (checkValue: boolean) => {
    if (checkValue) setFieldValue('openingHours', { from: '00:00', to: '24:00' })
    setIsFullDayChecked(checkValue)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" mb={1}>
          Opening Hours
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFullDayChecked}
              color="primary"
              onChange={(event) => handleCheckboxChange(event.currentTarget.checked)}
            />
          }
          label="24/7"
          sx={{ marginRight: '0px' }}
        />
      </Stack>

      <Stack direction="row" mb={1} maxWidth={'308px'} spacing={1}>
        <TextField
          name="openingHours.from"
          onChange={handleChange}
          error={!!errors.openingHours?.from}
          value={values.openingHours.from}
          placeholder="e.g. 00:00"
          label="From"
          variant="filled"
          disabled={props.disabled || isFullDayChecked}
        />

        <TextField
          name="openingHours.to"
          onChange={handleChange}
          error={!!errors.openingHours?.to}
          value={values.openingHours.to}
          placeholder="e.g. 24:00"
          label="To"
          variant="filled"
          disabled={props.disabled || isFullDayChecked}
        />
      </Stack>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting || props.disabled}
        style={{ minWidth: '150px' }}
      >
        {isSubmitting ? <CircularProgress size={24} color="inherit" /> : <span>Update Hours</span>}
      </Button>
    </form>
  )
}
