import { Button } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useAuth } from '../../Auth/AuthProvider'
import { WarehouseDisableContingencyModeDialog } from './WarehouseDisableContingencyModeDialog'
import { WarehouseEnableContingencyModeDialog } from './WarehouseEnableContingencyModeDialog'

interface Props {
  warehouse: Warehouse
}

export const ContingencyModeButton: FC<Props> = ({ warehouse }) => {
  const { claims } = useAuth()
  const [isEnableContingencyDialogOpen, setIsEnableContingencyDialogOpen] = useState(false)
  const [isDisableContingencyDialogOpen, setIsDisableContingencyDialogOpen] = useState(false)

  const toggleDialog = useCallback(
    (setFn: Dispatch<SetStateAction<boolean>>) => () => {
      setFn((prev) => !prev)
    },
    []
  )

  const { buttonText, buttonFunction } = useMemo(() => {
    if (warehouse.isInContingencyMode) {
      return {
        buttonText: 'Open Store',
        buttonFunction: toggleDialog(setIsDisableContingencyDialogOpen),
      }
    } else {
      return {
        buttonText: 'Close Store',
        buttonFunction: toggleDialog(setIsEnableContingencyDialogOpen),
      }
    }
  }, [warehouse, toggleDialog])

  if (!claims?.admin) {
    return null
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        style={{ textTransform: 'none', color: 'white' }}
        disabled={!!warehouse.inContingencyModeFor}
        onClick={buttonFunction}
      >
        {buttonText}
      </Button>

      {isEnableContingencyDialogOpen && (
        <WarehouseEnableContingencyModeDialog
          warehouse={warehouse}
          onClose={toggleDialog(setIsEnableContingencyDialogOpen)}
        />
      )}

      {isDisableContingencyDialogOpen && (
        <WarehouseDisableContingencyModeDialog
          warehouse={warehouse}
          onClose={toggleDialog(setIsDisableContingencyDialogOpen)}
        />
      )}
    </>
  )
}
