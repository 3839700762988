import * as Icons from 'react-feather'

export const warningIcons: { [key: string]: Icons.Icon } = {
  clock: Icons.Clock,
  'cloud-snow': Icons.CloudSnow,
  'cloud-drizzle': Icons.CloudDrizzle,
  'alert-triangle': Icons.AlertTriangle,
  info: Icons.Info,
  umbrella: Icons.Umbrella,
}

export const warningColors: { [key: string]: string } = {
  blue50: '#E5F8FF',
  green50: '#E5FFF8',
  orange100: '#FFF0D9',
  red50: '#FFD9D9',
}
