import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import { FC } from 'react'
import { Link, Outlet } from 'react-router-dom'
import AppTopBar from '../App/AppTopBar'
import { useAuth } from '../Auth/AuthProvider'
import ChipIsDC from '../ChipIsDC/ChipIsDC'
import { ContingencyModeBanner } from './components/ContingencyModeBanner'
import { ContingencyModeButton } from './components/ContingencyModeButton'
import { LimitedAccessBanner } from './components/LimitedAccessBanner'
import { WarehouseCoverageChip } from './components/WarehouseCoverageChip'
import WarehouseStatus from './components/WarehouseStatus'
import { useWarehouseFromRoute } from './hooks/useWarehouseFromRoute'
import { useWarehouseSubRoute } from './hooks/useWarehouseSubRoute'

export const WarehousePage: FC = () => {
  const { claims } = useAuth()
  const isAdmin = claims?.admin
  const canToggle = claims?.admin || claims?.manager
  const warehouse = useWarehouseFromRoute()
  const subRoute = useWarehouseSubRoute()

  if (!warehouse) return <Box p={2}>Can not find the store</Box>

  return (
    <>
      {/* Header */}
      <AppTopBar>
        <Stack flex={1}>
          <Typography variant="h6" color="textPrimary" noWrap>
            {warehouse.name}
            {warehouse?.isDistributionCenter && <ChipIsDC />}
            <WarehouseCoverageChip warehouse={warehouse} />
          </Typography>

          <WarehouseStatus warehouse={warehouse} />
        </Stack>

        <ContingencyModeButton warehouse={warehouse} />
      </AppTopBar>

      {/* Banners */}
      <ContingencyModeBanner
        isInContingencyMode={warehouse.isInContingencyMode}
        inContingencyModeFor={warehouse.inContingencyModeFor}
      />
      {!isAdmin && <LimitedAccessBanner readOnly={!canToggle} />}

      {/* Tabs */}
      <Tabs value={subRoute} indicatorColor="primary">
        <Tab label="Polygon" value="polygon" component={Link} to={`/warehouse/${warehouse.id}/polygon`} />
        <Tab label="Messages" value="messages" component={Link} to={`/warehouse/${warehouse.id}/messages`} />
        <Tab label="Settings" value="settings" component={Link} to={`/warehouse/${warehouse.id}/settings`} />
        <Tab label="Logs" value="logs" component={Link} to={`/warehouse/${warehouse.id}/logs`} />
      </Tabs>
      <Divider />

      {/* Content */}
      <Box p={2}>
        <Outlet />
      </Box>
    </>
  )
}
