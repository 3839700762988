import { OpeningHours } from '@quickcommerceltd/zone'
import { ActiveTime } from '../types/ActiveTime'
import { sanitizeActiveTimes } from './sanitizeActiveTimes'

export const findUncoveredTimeRanges = (
  activeTimes: ActiveTime[],
  openingHours: OpeningHours
): { weekday: number; notCovered: { from: string; to: string }[] }[] => {
  const result: {
    weekday: number
    notCovered: { from: string; to: string }[]
  }[] = []

  const sanitizedActiveTimes = sanitizeActiveTimes(activeTimes)
  const sanitizedOpeningHours = {
    from: openingHours?.from ?? '00:00',
    to: openingHours?.to ?? '24:00',
  }

  ;[1, 2, 3, 4, 5, 6, 7].forEach((weekday) => {
    const relevantActiveTimes = sanitizedActiveTimes.filter(
      (activeTime) =>
        activeTime.isoWeekday === weekday &&
        activeTime.to > sanitizedOpeningHours.from &&
        activeTime.from < sanitizedOpeningHours.to
    )
    const uncoveredRanges: { from: string; to: string }[] = []

    if (!relevantActiveTimes?.length) {
      uncoveredRanges.push({
        from: sanitizedOpeningHours.from,
        to: sanitizedOpeningHours.to,
      })
    } else {
      if (relevantActiveTimes[0].from > sanitizedOpeningHours.from) {
        uncoveredRanges.push({
          from: sanitizedOpeningHours.from,
          to: relevantActiveTimes[0].from,
        })
      }

      for (let index = 1; index < relevantActiveTimes.length; index++) {
        const prevRange = relevantActiveTimes[index - 1]
        const currRange = relevantActiveTimes[index]

        if (prevRange.to < currRange.from) {
          uncoveredRanges.push({ from: prevRange.to, to: currRange.from })
        }
      }

      const lastRange = relevantActiveTimes[relevantActiveTimes.length - 1]

      if (lastRange.to < sanitizedOpeningHours.to) {
        uncoveredRanges.push({
          from: lastRange.to,
          to: sanitizedOpeningHours.to,
        })
      }
    }

    result.push({ weekday, notCovered: uncoveredRanges })
  })

  return result
}
