import { Warehouse, WarehousePolygon } from '@quickcommerceltd/zone'
import { usePolygons } from '../../Polygon/PolygonsProvider'
import { findUncoveredTimeRanges } from '../helpers/findUncoveredTimeRanges'
import { ActiveTime } from '../types/ActiveTime'

interface NotCoveredWeekday {
  weekday: number
  notCovered: { from: string; to: string }[]
}

interface Coverage {
  isFullyCovered: boolean
  notCoveredWeekdays?: NotCoveredWeekday[]
}

export const useWarehouseOpeningHoursCoverage = (warehouse: Warehouse, formPolygon?: WarehousePolygon): Coverage => {
  const [warehousePolygons] = usePolygons()
  const openingHours = warehouse.openingHours ?? {
    from: '00:00',
    to: '24:00',
  }
  const warehousePolygon = formPolygon ?? warehousePolygons?.filter((polygon) => polygon.id === warehouse.id)?.[0]

  // all active polygons of a single warehouse
  const polygons = warehousePolygon?.paths?.filter(
    (polygon) => !polygon.contingencyPolygonFor && !polygon.isDraft && polygon.points?.length
  )

  const defaultCoverageWeekdays: NotCoveredWeekday[] = [1, 2, 3, 4, 5, 6, 7].map((weekday) => {
    return {
      weekday,
      notCovered: [{ from: openingHours.from, to: openingHours.to }],
    }
  })

  if (!polygons?.length) {
    return {
      isFullyCovered: false,
      notCoveredWeekdays: defaultCoverageWeekdays,
    }
  }

  if (polygons.find((polygon) => polygon.isAlwaysActive))
    return {
      isFullyCovered: true,
      notCoveredWeekdays: defaultCoverageWeekdays.map((day) => {
        return { weekday: day.weekday, notCovered: [] }
      }),
    }

  const activeTimes = polygons.flatMap((polygon) => polygon.activeTimes)

  if (!activeTimes?.length || !activeTimes?.[0]) {
    return {
      isFullyCovered: false,
      notCoveredWeekdays: defaultCoverageWeekdays,
    }
  }

  const uncoveredRangesPerWeekday = findUncoveredTimeRanges(activeTimes as ActiveTime[], openingHours)

  const isFullyCovered = uncoveredRangesPerWeekday.reduce<boolean>((result, current) => {
    if (!!current.notCovered?.length) return false
    return result
  }, true)

  return { isFullyCovered, notCoveredWeekdays: uncoveredRangesPerWeekday }
}
