import Edit from '@mui/icons-material/Edit'
import EventIcon from '@mui/icons-material/Event'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, Card, FormControlLabel, IconButton, Switch } from '@mui/material'
import { ThrottlingConfig } from '@quickcommerceltd/zone'
import { FC, useState } from 'react'
import { useAuth } from '../../Auth/AuthProvider'
import { StoreWarningView } from '../../Warehouse/components/StoreWarningView'
import { useUpdateThrottlingConfig } from '../hooks/useUpdateThrottlingConfig'
import { StoreThrottlingForm } from './StoreThrottlingForm'

interface Props {
  warehouseId: string
  throttlingConfig: ThrottlingConfig
}

export const StoreThrottlingItem: FC<Props> = ({ warehouseId, throttlingConfig }) => {
  const [updateThrottlingConfig, isUpdatingThrottlingConfig] = useUpdateThrottlingConfig()
  const auth = useAuth()
  const isAdmin = auth.claims?.admin
  const canToggle = isAdmin || auth.claims?.manager
  const isActiveNow = warehouseId === throttlingConfig.id
  const [isEditing, setEditing] = useState(false)

  return (
    <Box mb={2}>
      <Card style={{ boxShadow: isActiveNow ? '0 0 0 4px #02BAFF' : undefined }}>
        <Box display="flex" px={1} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IconButton color="primary" disabled={!isAdmin} onClick={() => setEditing(!isEditing)}>
              <Edit />
            </IconButton>
            <FormControlLabel
              label={throttlingConfig.enabled ? 'On' : 'Off'}
              control={
                <Switch
                  disabled={!canToggle || isUpdatingThrottlingConfig}
                  color="primary"
                  checked={throttlingConfig.enabled}
                  onChange={() => {
                    updateThrottlingConfig({
                      warehouseId,
                      throttlingConfig: {
                        ...throttlingConfig,
                        enabled: !throttlingConfig.enabled,
                      },
                    })
                  }}
                />
              }
            />
          </Box>
          <Box display="flex">
            {throttlingConfig.closeTemporary && (
              <Box mx={0.5} display="flex">
                <NotInterestedIcon fontSize="small" />
                &nbsp;Closing
              </Box>
            )}
            <Box mx={0.5} display="flex">
              <EventIcon fontSize="small" />
              &nbsp;{throttlingConfig.activeTimes ? 'Scheduled' : '24/7'}
            </Box>
            <Box mx={0.5} display="flex">
              <ShoppingCartIcon fontSize="small" />
              &nbsp;{throttlingConfig.stackSize}&nbsp;orders
            </Box>
          </Box>
        </Box>

        {!isEditing && throttlingConfig.message && throttlingConfig.icon && throttlingConfig.color && (
          <StoreWarningView value={throttlingConfig} />
        )}
      </Card>

      {isEditing && (
        <StoreThrottlingForm
          warehouseId={warehouseId}
          throttlingConfig={throttlingConfig}
          onClose={() => setEditing(false)}
        />
      )}
    </Box>
  )
}
