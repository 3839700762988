import { Alert, Box, Button, Typography } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { FC, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Dialog, LoadingButton } from '../../Common/components'
import { isCurrentlyActive } from '../../Polygon/helpers/isCurrentlyActive'
import { usePolygons } from '../../Polygon/PolygonsProvider'
import { toggleContingencyModeCallable } from '../callables/toogleContingencyMode'
import { useWarehouses } from '../WarehousesProvider'

interface Props {
  warehouse: Warehouse
  onClose: () => void
}

export const WarehouseEnableContingencyModeDialog: FC<Props> = ({ warehouse, onClose }) => {
  const [warehouses] = useWarehouses()
  const [polygons] = usePolygons()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const activeContingencyPolygons = useMemo(() => {
    return polygons?.reduce<[string, string][]>((result, curr) => {
      const providingWarehouse = warehouses?.find((wh) => curr.id === wh.id)
      const path = curr.paths.find(
        (path) => path.contingencyPolygonFor === warehouse.id && isCurrentlyActive(path.activeTimes)
      )

      if (path && providingWarehouse && !providingWarehouse.isInContingencyMode) {
        result.push([providingWarehouse.name, path.name])
      }

      return result
    }, [])
  }, [warehouse.id, polygons, warehouses])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    await toggleContingencyModeCallable({
      warehouseId: warehouse.id,
      enable: true,
    })
      .then(() => toast.success('Store closed successfully'))
      .catch((error) => toast.error(error.message))
      .finally(() => {
        setIsSubmitting(false)
        onClose()
      })
  }, [warehouse.id, onClose])

  return (
    <Dialog open={true} onClose={onClose} title="Confirm Store Closure">
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        {warehouse.name} ({warehouse.shortName})
      </Typography>

      <Box my={3}>
        {!!activeContingencyPolygons?.length ? (
          <>
            <Typography variant="body1">The warehouse area will be covered by: </Typography>
            {activeContingencyPolygons?.map(([warehouseId, pathName]) => (
              <Typography key={warehouseId} variant="body1">
                · <b>{warehouseId}</b>: {pathName}
              </Typography>
            ))}
          </>
        ) : (
          <Alert severity="error">No warehouse will cover the closed area of {warehouse.shortName}!</Alert>
        )}
      </Box>

      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        color="primary"
        size="large"
        disableElevation
        style={{ textTransform: 'none', color: 'white' }}
        onClick={handleSubmit}
        fullWidth
        disabled={isSubmitting}
      >
        Confirm
      </LoadingButton>
      <Button variant="text" color="primary" size="large" onClick={onClose} fullWidth>
        Cancel
      </Button>
    </Dialog>
  )
}
