import InfoIcon from '@mui/icons-material/Info'
import { FormControlLabel, Switch, Tooltip } from '@mui/material'
import { UpdateFeatureFlagRequest, FeatureFlag } from '@quickcommerceltd/zone'
import React, { useCallback, useEffect, useState } from 'react'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { toast } from 'react-hot-toast'
import { getFeatureFlagRef } from '../../firebase/helpers/getDocRef'
import { useAuth } from '../Auth/AuthProvider'
import { updateFeatureFlagCallable } from './updateFeatureFlagCallable'

const FEATURE_FLAG_NAME = 'enableAutoContingencyOnThrottle'

export const AutoContingency: React.FC = () => {
  const auth = useAuth()
  const [featureFlag, isFeatureFlagLoading] = useDocumentDataOnce<FeatureFlag>(getFeatureFlagRef(FEATURE_FLAG_NAME))
  const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] = useState(featureFlag?.enabled ?? false)
  const [isUpdating, setIsUpdating] = useState(false)
  const isAdmin = auth.claims?.admin

  useEffect(() => {
    setIsFeatureFlagEnabled(featureFlag?.enabled ?? false)
  }, [featureFlag])

  const updateFeatureFlag = useCallback(async (updateFeatureFlagRequest: UpdateFeatureFlagRequest) => {
    try {
      setIsUpdating(true)
      await updateFeatureFlagCallable(updateFeatureFlagRequest)
      setIsFeatureFlagEnabled(updateFeatureFlagRequest.enabled)
    } catch (error: any) {
      toast.error(error.message)
    } finally {
      setIsUpdating(false)
    }
  }, [])

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    await updateFeatureFlag({
      featureFlag: FEATURE_FLAG_NAME,
      enabled: checked,
    })
  }

  return (
    <>
      <Tooltip
        sx={{ mr: 1 }}
        arrow
        title="Automatically deploy and un-depoloy contingency polygons when we auto-throttle"
      >
        <InfoIcon />
      </Tooltip>
      <FormControlLabel
        control={
          <Switch
            disabled={!isAdmin || isUpdating || isFeatureFlagLoading}
            color="primary"
            name="isAutoContingenyEnabled"
            checked={isFeatureFlagEnabled}
            value={isFeatureFlagEnabled}
            onChange={onChange}
          />
        }
        label="Auto Contingency"
      />
    </>
  )
}
