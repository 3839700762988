import { Warehouse, WarehousePolygon, FeatureFlag } from '@quickcommerceltd/zone'
import { doc, DocumentReference } from 'firebase/firestore'
import { UserClaim } from '../../Components/Auth/Authorize'
import { CollectionName } from '../CollectionName'
import { db } from '../firebase'

function getDocRef<T>(collectionName: CollectionName, docId: string) {
  return doc(db, collectionName, docId) as DocumentReference<T>
}

export function getUserClaimsDocRef(docId: string) {
  return getDocRef<UserClaim>('user_claims', docId)
}

export function getWarehouseDocRef(docId: string) {
  return getDocRef<Warehouse>('warehouses', docId)
}

export function getWarehousePolygonDocRef(docId: string) {
  return getDocRef<WarehousePolygon>('warehousePolygons', docId)
}

export function getFeatureFlagRef(docId: string) {
  return getDocRef<FeatureFlag>('featureFlags', docId)
}
