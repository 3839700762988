import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import Authorize from '../Auth/Authorize'
import { LogsPage } from '../Log/LogsPage'
import { WarehouseMessagesPage } from '../Messages/WarehouseMessagesPage'
import { WarehousePolygonPage } from '../Polygon/WarehousePolygonPage'
import { WarehouseSettingsPage } from '../Settings/WarehouseSettingsPage'
import WarehouseCreateView from '../Warehouse/WarehouseCreateView'
import { WarehousePage } from '../Warehouse/WarehousePage'

export const AppRouter: FC = () => {
  return (
    <Routes>
      <Route index element={<WarehouseCreateView />} />
      <Route path="warehouse/:warehouseId" element={<WarehousePage />}>
        <Route path="polygon" element={<WarehousePolygonPage />} />
        <Route path="messages" element={<WarehouseMessagesPage />} />
        <Route path="settings" element={<WarehouseSettingsPage />} />
        <Route path="logs" element={<LogsPage />} />
      </Route>
      <Route path="authorize" element={<Authorize />} />
    </Routes>
  )
}
