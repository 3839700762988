import { Timestamp } from '@quickcommerceltd/zone'
import { DateTime } from 'luxon'
import { FC, useCallback, useEffect, useState } from 'react'
import { getDiffTranslation } from '../helpers/getDiffTranslation'

interface Props {
  timestamp: Timestamp
  translation: `${string}{diff}${string}`
}

export const RelativeTimestampView: FC<Props> = (props) => {
  const seconds = Math.floor(DateTime.fromMillis(props.timestamp.toMillis()).diffNow('seconds').seconds)
  const time = Math.abs(Math.min(seconds, 0))

  const getValue = useCallback(
    () => props.translation.replace('{diff}', getDiffTranslation(time)),
    [props.translation, time]
  )
  const [value, setValue] = useState(getValue())

  useEffect(() => {
    const interval = setInterval(() => setValue(getValue()), 1000)

    return () => clearInterval(interval)
  }, [getValue])

  return <>{value}</>
}
