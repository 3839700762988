import { Alert, Box, CircularProgress, Grid, List } from '@mui/material'
import { Log } from '@quickcommerceltd/zone'
import { limit, orderBy, query } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getWarehouseLogCollectionRef } from '../../../firebase/helpers/getCollectionRef'
import { getWarehouseLogsFromSnapshot } from '../../../firebase/helpers/getDataFromQuerySnapshot'
import { LogListItemView } from './LogListItemView'

interface Props {
  warehouseId: string
  onLogClick: (title: string, log: Log) => void
}

export function LogListView(props: Props) {
  const [snapshot, isLoading, error] = useCollection(
    query(getWarehouseLogCollectionRef(props.warehouseId), orderBy('createdAt', 'desc'), limit(20))
  )
  const logs = useMemo(
    () => getWarehouseLogsFromSnapshot(snapshot).sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
    [snapshot]
  )

  const isLoaded = !isLoading && !error

  return (
    <Grid item sm={12} md={4}>
      {isLoading && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box p={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
      {isLoaded && (
        <List>
          {logs.length === 0 && (
            <Box p={2}>
              <Alert severity="info">No logs</Alert>
            </Box>
          )}
          {logs.map((log, index) => (
            <LogListItemView
              key={`${log.createdAt.toMillis()}-${index}`}
              log={log}
              isFirstItem={index === 0}
              isLastItem={index === logs.length - 1}
              onClick={(title: string) => props.onLogClick(title, log)}
            />
          ))}
        </List>
      )}
    </Grid>
  )
}
