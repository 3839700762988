import { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '../Auth/AuthProvider'
import PolygonsProvider from '../Polygon/PolygonsProvider'
import WarehousesProvider from '../Warehouse/WarehousesProvider'
import AppView from './AppView'
import { ThemeProvider } from './ThemeProvider'

const AppProvider: FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <WarehousesProvider>
          <PolygonsProvider>
            <BrowserRouter>
              <AppView />
              <Toaster />
            </BrowserRouter>
          </PolygonsProvider>
        </WarehousesProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default AppProvider
