import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Button, Stack, Typography } from '@mui/material'
import { Warehouse, WarehousePolygon } from '@quickcommerceltd/zone'
import { FC } from 'react'
import { Dialog, LoadingButton } from '../../Common/components'
import { WarehouseCoverageTable } from '../../Warehouse/components/WarehouseCoverageTable'
import { useWarehouseOpeningHoursCoverage } from '../../Warehouse/hooks/useWarehouseOpeningHoursCoverage'
import { LongPressButton } from './LongPressButton'

interface Props {
  warehouse: Warehouse
  onClose: () => void
  handleSubmit: () => void
  isSubmitting: boolean
  formPolygon: WarehousePolygon
}

export const WarehousePolygonSaveDialog: FC<Props> = (props) => {
  const coverage = useWarehouseOpeningHoursCoverage(props.warehouse, props.formPolygon)

  return (
    <Dialog open={true} onClose={props.onClose} title="Confirm Polygon Update" maxWidth="sm">
      <Stack>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '-14px' }}>
          {props.warehouse.name} ({props.warehouse.shortName})
        </Typography>
        <Typography>
          {`Opening Hours: ${props.warehouse.openingHours?.from ?? '00:00'} - ${
            props.warehouse.openingHours?.to ?? '24:00'
          }`}
        </Typography>
      </Stack>

      {coverage.isFullyCovered ? (
        <Stack direction="row">
          <Typography
            color="success.main"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 0px',
            }}
          >
            <CheckCircleOutlinedIcon sx={{ marginRight: '4px', marginBottom: '4px' }} />
            The store is fully covered
          </Typography>
        </Stack>
      ) : (
        <Stack>
          <Typography
            color="error"
            fontWeight={700}
            fontSize="1.25rem"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 0px',
            }}
          >
            <ReportProblemOutlinedIcon sx={{ marginRight: '4px', marginBottom: '4px' }} />
            The store is not fully covered
          </Typography>
          <Typography color="error">
            Please review the "days/times" when the store is <b>not covered</b> by any polygon to ensure the store
            remains operational!
          </Typography>
        </Stack>
      )}

      <Stack py={2}>
        <WarehouseCoverageTable
          isFullyCovered={coverage.isFullyCovered}
          notCoveredWeekdays={coverage.notCoveredWeekdays}
          fontSize={'1rem'}
        />
      </Stack>

      {coverage.isFullyCovered ? (
        <LoadingButton
          loading={props.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          style={{ textTransform: 'none', color: 'white' }}
          onClick={props.handleSubmit}
          fullWidth
          disabled={props.isSubmitting}
        >
          Confirm Polygon Update
        </LoadingButton>
      ) : (
        <LongPressButton
          loading={props.isSubmitting}
          disabled={props.isSubmitting}
          threshold={1000}
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          onClick={props.handleSubmit}
          style={{ textTransform: 'none', color: 'white' }}
        >
          Hold To Confirm Polygon Update
        </LongPressButton>
      )}

      <Button variant="text" color="primary" size="large" onClick={props.onClose} fullWidth>
        Cancel
      </Button>
    </Dialog>
  )
}
