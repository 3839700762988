import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogProps, Divider, IconButton, Dialog as MuiDialog, Typography } from '@mui/material'
import { FC } from 'react'

interface Props extends DialogProps {
  title: string
}

export const Dialog: FC<Props> = (props) => {
  const { title, children, ...dialogProps } = props

  return (
    <MuiDialog
      open={!!dialogProps.open}
      onClose={dialogProps.onClose}
      PaperProps={{ style: { borderRadius: 16, minWidth: '35%' } }}
    >
      <Box display="flex" flexDirection="column">
        <Box p={2} py={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>

          <IconButton onClick={(e) => props.onClose?.(e, 'backdropClick')}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" p={4}>
          {children}
        </Box>
      </Box>
    </MuiDialog>
  )
}
