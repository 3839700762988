import { isLog, Log } from '@quickcommerceltd/zone'
import { QuerySnapshot } from 'firebase/firestore'

export function getDataFromCollectionSnapshot<T>(isT: (obj: unknown) => obj is T, snapshot?: QuerySnapshot<T>) {
  if (!snapshot) return []

  return snapshot.docs.reduce<T[]>((result, doc) => {
    if (doc.exists()) {
      const data = doc.data()

      if (isT(data)) {
        result.push(data)
      }
    }

    return result
  }, [])
}

export function getWarehouseLogsFromSnapshot(snapshot?: QuerySnapshot<Log>) {
  return getDataFromCollectionSnapshot(isLog, snapshot)
}
