import Edit from '@mui/icons-material/Edit'
import { Box, Card, FormControlLabel, IconButton, Switch } from '@mui/material'
import { StoreWarning } from '@quickcommerceltd/zone'
import { FC, useState } from 'react'
import { useAuth } from '../../Auth/AuthProvider'
import { StoreWarningView } from '../../Warehouse/components/StoreWarningView'
import { useUpdateStoreWarning } from '../hooks/useUpdateStoreWarning'
import { StoreWarningForm } from './StoreWarningForm'

interface Props {
  storeWarning: StoreWarning
  isActiveNow: boolean
  warehouseId: string
}

export const StoreWarningItem: FC<Props> = ({ storeWarning, isActiveNow, warehouseId }) => {
  const [updateStoreWarning, isUpdatingStoreWarning] = useUpdateStoreWarning()
  const auth = useAuth()
  const isAdmin = auth.claims?.admin
  const canToggle = isAdmin || auth.claims?.manager
  const [isEditing, setEditing] = useState(false)

  return (
    <Box mb={2}>
      <Card style={{ boxShadow: isActiveNow ? '0 0 0 4px #02BAFF' : undefined }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <IconButton color="primary" disabled={!isAdmin} onClick={() => setEditing(!isEditing)}>
              <Edit />
            </IconButton>
          </Box>
          <Box mx={1}>
            <FormControlLabel
              label="Enabled"
              control={
                <Switch
                  color="primary"
                  checked={isActiveNow}
                  disabled={!canToggle || isUpdatingStoreWarning}
                  onChange={(event) =>
                    updateStoreWarning({
                      warehouseId,
                      enabled: event.target.checked,
                      storeWarning,
                    })
                  }
                />
              }
            />
          </Box>
        </Box>
        {!isEditing && <StoreWarningView value={storeWarning} />}
      </Card>
      {isEditing && (
        <StoreWarningForm warehouseId={warehouseId} storeWarning={storeWarning} onClose={() => setEditing(false)} />
      )}
    </Box>
  )
}
