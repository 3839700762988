import { Alert, Box } from '@mui/material'
import { FC } from 'react'

interface Props {
  isInContingencyMode?: boolean
  inContingencyModeFor?: string
}

export const ContingencyModeBanner: FC<Props> = ({ isInContingencyMode, inContingencyModeFor }) => {
  if (!isInContingencyMode && !inContingencyModeFor) return null

  return (
    <Box mb={1}>
      <Alert severity="error">
        This warehouse is in contingency mode
        {inContingencyModeFor ? ` for ${inContingencyModeFor}.` : '.'}
      </Alert>
    </Box>
  )
}
