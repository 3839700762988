import { Box, CircularProgress } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { FirestoreError, limit, query } from 'firebase/firestore'
import React, { FC, useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getWarehouseCollectionRef } from '../../firebase/helpers/getCollectionRef'

type WarehousesValue = [Warehouse[] | undefined, boolean, FirestoreError | undefined]

const defaultValue: WarehousesValue = [[], false, undefined]

const WarehousesContext = React.createContext(defaultValue)

export const useWarehouses = () => {
  const value = useContext(WarehousesContext)
  return value
}

const WarehousesProvider: FC = ({ children }) => {
  const value = useCollectionData<Warehouse>(query(getWarehouseCollectionRef(), limit(500)))

  value?.[0]?.sort((a, b) => a.name.localeCompare(b.name))
  const isLoading = value?.[1]

  return (
    <WarehousesContext.Provider value={value}>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && children}
    </WarehousesContext.Provider>
  )
}

export default WarehousesProvider
