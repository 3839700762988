import { Alert } from '@mui/material'
import { DeleteThrottlingConfigRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { deleteThrottlingConfigCallable } from '../callables/deleteThrottlingConfigCallable'

export function useDeleteThrottlingConfig(): [
  (deleteThrottlingConfigRequest: DeleteThrottlingConfigRequest) => Promise<void>,
  boolean,
] {
  const [isDeletingThrottlingConfig, setIsDeletingThrottlingConfig] = useState(false)

  const deleteThrottlingConfig = useCallback(async (deleteThrottlingConfigRequest: DeleteThrottlingConfigRequest) => {
    try {
      setIsDeletingThrottlingConfig(true)

      await deleteThrottlingConfigCallable(deleteThrottlingConfigRequest)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsDeletingThrottlingConfig(false)
    }
  }, [])

  return [deleteThrottlingConfig, isDeletingThrottlingConfig]
}
