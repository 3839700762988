import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Card, Divider, Grid, IconButton, TextField } from '@mui/material'
import { StoreWarning } from '@quickcommerceltd/zone'
import { useFormik } from 'formik'
import { FC } from 'react'
import shortid from 'shortid'
import ColorPicker from '../../ColorPicker/ColorPicker'
import IconPicker from '../../IconPicker/IconPicker'
import { useDeleteStoreWarning } from '../hooks/useDeleteStoreWarning'
import { useUpdateStoreWarning } from '../hooks/useUpdateStoreWarning'

interface Props {
  warehouseId: string
  storeWarning?: StoreWarning
  onClose: () => void
}

export const StoreWarningForm: FC<Props> = (props) => {
  const [updateStoreWarning, isUpdatingStoreWarning] = useUpdateStoreWarning()
  const [deleteStoreWarning, isDeletingStoreWarning] = useDeleteStoreWarning()
  const isSubmitting = isUpdatingStoreWarning || isDeletingStoreWarning
  const storeWarningId = props.storeWarning?.id

  const defaultWarning: StoreWarning = {
    id: shortid.generate(),
    message: '',
    color: 'orange100',
    icon: 'clock',
  }

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      ...defaultWarning,
      ...props.storeWarning,
    },
    onSubmit: async (values) => {
      await updateStoreWarning({
        warehouseId: props.warehouseId,
        enabled: true,
        storeWarning: values,
      })
      props.onClose()
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <IconPicker required fullWidth name="icon" label="Icon" value={values.icon} onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <ColorPicker required name="color" label="Color" value={values.color} onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                multiline
                name="message"
                label="Message"
                fullWidth
                variant="outlined"
                value={values.message}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
          {storeWarningId && (
            <Box mx={2}>
              <IconButton
                color="primary"
                disabled={isSubmitting}
                onClick={() =>
                  deleteStoreWarning({
                    storeWarningId: storeWarningId,
                    warehouseId: props.warehouseId,
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained" disabled={isUpdatingStoreWarning}>
            {props.storeWarning ? 'Save' : 'Create'}
          </Button>
        </Box>
      </Card>
    </form>
  )
}
