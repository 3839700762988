import { Box, colors, Divider, Drawer, List, ListItem, ListItemText } from '@mui/material'
import { WarningIcons } from '@quickcommerceltd/zone'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { useAuth } from '../Auth/AuthProvider'
import { AutoContingency } from '../AutoContingency/AutoContingency'
import ChipIsDC from '../ChipIsDC/ChipIsDC'

import { WarehouseStatusIndicator } from '../Warehouse/components/WarehouseStatusIndicator'
import WarehouseWarning from '../Warehouse/components/WarehouseWarning'
import { useWarehouseSubRoute } from '../Warehouse/hooks/useWarehouseSubRoute'
import { useWarehouses } from '../Warehouse/WarehousesProvider'

const AppSidebarView = () => {
  const auth = useAuth()
  const { classes } = useStyles()
  const [warehouses] = useWarehouses()
  const location = useLocation()
  const subRoute = useWarehouseSubRoute()
  const isAdmin = auth.claims?.admin

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box className={classes.toolbar} alignItems="center" justifyContent="center" display="flex">
        <img src="/logo.png" alt="Zone" width={120} />
      </Box>
      <Divider />
      <Box alignItems="center" justifyContent="center" display="flex">
        <AutoContingency />
      </Box>
      <Divider />
      <List disablePadding>
        <ListItem component={Link} to="/" selected={location.pathname === '/'} button className={classes.nested}>
          {isAdmin && <ListItemText secondary="+ Create new store" />}
        </ListItem>
        {(warehouses ?? []).map((warehouse, index) => (
          <ListItem
            component={Link}
            to={`/warehouse/${warehouse.id}/${subRoute}`}
            key={index}
            selected={location.pathname.startsWith(`/warehouse/${warehouse.id}`)}
            button
            className={classes.nested}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>{warehouse.name}</Box>
              <Box mr={1} sx={{ fontSize: 'smaller', color: colors.grey[500] }}>
                ({warehouse.shortName})
              </Box>
              {warehouse.isDistributionCenter && (
                <Box mr={1}>
                  <ChipIsDC nav />
                </Box>
              )}
              <Box display="flex" flex={1} />
              {warehouse.isInContingencyMode && (
                <Box mr={1} sx={{ fontSize: 'smaller', fontWeight: 'bold' }}>
                  <WarehouseWarning
                    warning={{
                      id: 'contingency-mode',
                      message: ['Contingency Mode', warehouse.inContingencyModeFor].filter(Boolean).join(': '),
                      color: warehouse.inContingencyModeFor ? 'orange100' : 'red50',
                      icon: 'umbrella' as WarningIcons,
                    }}
                  />
                </Box>
              )}
              {warehouse.warning && (
                <Box mr={1}>
                  <WarehouseWarning warning={warehouse.warning} />
                </Box>
              )}
              <Box mr={1}>
                <WarehouseStatusIndicator warehouse={warehouse} />
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default AppSidebarView

export const SIDEBAR_WIDTH = 320

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: SIDEBAR_WIDTH,
  },
  toolbar: {
    minHeight: 64,
  }, //theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'block',
  },
}))
