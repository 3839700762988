import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { WarehouseStatus } from '@quickcommerceltd/zone'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'

const WarehouseStatusModal = (props: {
  isOpen: boolean
  isLoading: boolean
  nextWarehouseStatus: WarehouseStatus
  onClose: () => void
  onSubmit: (reason: string) => void
}) => {
  const { classes } = useStyles()
  const [reason, setReason] = useState<string>('')
  const [otherReason, setOtherReason] = useState<string>('')

  const onPressClose = () => {
    setReason('')
    setOtherReason('')
    props.onClose()
  }

  return (
    <Modal open={props.isOpen}>
      <Box className={classes.paper}>
        <CloseIcon onClick={onPressClose} color="primary" className={classes.closeIcon} />
        {props.nextWarehouseStatus === 'OPEN' ? (
          <>
            <Typography variant="h5" className={classes.text}>
              Do you want to open the store?
            </Typography>
            <Box>
              <Button
                className={classes.closeButton}
                color="primary"
                variant="outlined"
                disabled={props.isLoading}
                onClick={() => props.onSubmit('Opened store')}
              >
                {props.isLoading ? <CircularProgress size={16} color="inherit" /> : 'Save'}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5" className={classes.text}>
              Why do you need to close the store?
            </Typography>
            <FormControl required variant="outlined" className={classes.input}>
              <InputLabel>Select the closing reason</InputLabel>
              <Select
                id="code"
                label="Select the reason"
                value={reason || ''}
                onChange={(e) => {
                  setOtherReason('')
                  setReason(e.target.value as string)
                }}
              >
                <MenuItem value="" disabled>
                  Select the reason
                </MenuItem>
                <MenuItem value="Backlog of orders">BACKLOG</MenuItem>
                <MenuItem value="No riders available">NO RIDERS</MenuItem>
                <MenuItem value="Other">OTHER</MenuItem>
              </Select>
            </FormControl>
            <Box>
              <TextField
                className={classes.input}
                disabled={reason !== 'Other'}
                variant="outlined"
                label="Specify other"
                value={otherReason || ''}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setOtherReason(e.target.value as string)}
              ></TextField>
            </Box>
            <Box>
              <Button
                className={classes.closeButton}
                color="primary"
                variant="outlined"
                value={otherReason || ''}
                disabled={props.isLoading || !reason || (reason === 'Other' && !otherReason)}
                onClick={() => props.onSubmit(reason === 'Other' ? otherReason : reason)}
              >
                {props.isLoading ? <CircularProgress size={16} color="inherit" /> : 'Save'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default WarehouseStatusModal

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'min(100%, 600px)',
    padding: theme.spacing(6, 4, 4),
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    fontSize: '2rem',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    margin: '16px 0',
  },
  closeButton: {
    height: '36px',
    marginTop: '16px',
    fontSize: '20px',
  },
  text: {
    marginBottom: '16px',
  },
}))
