import { Alert } from '@mui/material'
import { UpdateAutomaticThrottlingRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { updateAutomaticThrottlingCallable } from '../callables/updateAutomaticThrottlingCallable'

export function useUpdateAutomaticThrottling(): [
  (updateAutomaticThrottlingRequest: UpdateAutomaticThrottlingRequest) => Promise<void>,
  boolean,
] {
  const [isUpdatingAutomaticThrottling, setIsUpdatingAutomaticThrottling] = useState(false)

  const updateAutomaticThrottling = useCallback(
    async (updateAutomaticThrottlingRequest: UpdateAutomaticThrottlingRequest) => {
      try {
        setIsUpdatingAutomaticThrottling(true)

        await updateAutomaticThrottlingCallable(updateAutomaticThrottlingRequest)
      } catch (error: any) {
        Alert(error.message)
      } finally {
        setIsUpdatingAutomaticThrottling(false)
      }
    },
    []
  )

  return [updateAutomaticThrottling, isUpdatingAutomaticThrottling]
}
