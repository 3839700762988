import { ButtonProps, styled } from '@mui/material'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { LoadingButton } from '../../Common/components'

interface Props extends ButtonProps {
  loading?: boolean
  threshold?: number
  onClick?: () => void
}

export const LongPressButton: FC<Props> = ({ loading = false, threshold = 1000, onClick, ...props }) => {
  const timerRef = useRef<NodeJS.Timeout>()
  const [confirming, setConfirming] = useState(false)

  const start = useCallback(() => {
    setConfirming(true)
    timerRef.current = setTimeout(() => {
      setConfirming(false)
      onClick?.()
    }, threshold)
  }, [onClick, threshold])

  const stop = useCallback(() => {
    setConfirming(false)
    if (timerRef?.current) {
      clearTimeout(timerRef.current)
    }
  }, [])

  const longPressProps = useMemo(
    () => ({
      onMouseDown: start,
      onMouseUp: stop,
      onMouseLeave: stop,
      onTouchStart: start,
      onTouchEnd: stop,
    }),
    [start, stop]
  )

  return (
    <StyledButton
      loading={loading}
      confirming={Number(confirming)}
      threshold={threshold}
      disableElevation
      disableRipple
      {...props}
      {...longPressProps}
    />
  )
}

const StyledButton = styled(LoadingButton)<{
  confirming: boolean | number
  threshold: number
  loading: boolean
}>(({ children, loading, confirming, threshold }) => ({
  overflow: 'hidden',

  '@keyframes fill': {
    '0%': {
      right: '100%',
      opacity: 0.3,
    },
    '100%': {
      right: 0,
      opacity: 1,
    },
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    background: '#1B1464',
    bottom: 0,
    left: 0,
    right: '100%',
    top: 0,
    zIndex: 0,
    animation: confirming ? `fill ${threshold / 1000}s forwards` : 'inherit',
  },

  '&:after': {
    content: `"${loading ? '' : children}"`,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
}))
