import { Alert } from '@mui/material'
import { UpdateWarehouseStatusRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { updateWarehouseStatusCallable } from '../callables/updateWarehouseStatusCallable'

export function useUpdateWarehouseStatus(): [
  (nextWarehouseStatus: UpdateWarehouseStatusRequest) => Promise<void>,
  boolean,
] {
  const [isUpdatingWarehouseStatus, setIsUpdatingWarehouseStatus] = useState(false)

  const updateWarehouseStatus = useCallback(async (nextWarehouseStatus: UpdateWarehouseStatusRequest) => {
    try {
      setIsUpdatingWarehouseStatus(true)

      await updateWarehouseStatusCallable(nextWarehouseStatus)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsUpdatingWarehouseStatus(false)
    }
  }, [])

  return [updateWarehouseStatus, isUpdatingWarehouseStatus]
}
