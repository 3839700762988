import { WarehousePath, WarehousePolygon } from '@quickcommerceltd/zone'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'

export function getWarehousePath(
  polygon: WarehousePolygon,
  isoWeekday: number,
  minute: number
): WarehousePath | undefined {
  if (polygon.paths.length) {
    const selectedWarehousePath = polygon.paths.find((path) => {
      if (path.isDraft) return false
      if (path.isAlwaysActive) return true

      return !!path.activeTimes?.find((activeTime) => {
        const isSameWeekday = isoWeekday === activeTime.isoWeekday
        const now = DateTime.local().startOf('day').set({ minute })
        const from = DateTime.fromFormat(activeTime.from, 'HH:mm')
        const to = DateTime.fromFormat(activeTime.to, 'HH:mm')

        return isSameWeekday && now >= from && now < to
      })
    })

    return selectedWarehousePath
  } else {
    // TODO: Remove fallback as soon as all warehouse configs are set!
    const warehousePath = {
      id: v4(),
      isAlwaysActive: true,
      name: 'Default',
      points: [],
    }

    polygon.paths.push(warehousePath)

    return warehousePath
  }
}
