import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme, createTheme } from '@mui/material/styles'
import '@mui/styles'
import { FC } from 'react'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      light: '#B9CED8',
      main: '#608CA1',
      dark: '#3E5D6C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F8F9FA',
      main: '#E9F0F3',
      dark: '#B9CED8',
      contrastText: '#000',
    },
    text: {
      primary: '#002533',
      secondary: '#3E5D6C',
    },
    success: {
      light: '#E5FFF8',
      main: '#00805C',
    },
    warning: {
      light: '#FFF0D9',
      main: '#F59401',
      dark: '#A15E07',
    },
    error: {
      light: '#D83232',
      main: '#990F0F',
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          zIndex: 1200,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          lineHeight: '1.2em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: { textTransform: 'none', fontWeight: 600 },
        textSizeSmall: { textTransform: 'uppercase' },
      },
    },
  },
})

export const ThemeProvider: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <>
        <CssBaseline />
        {children}
      </>
    </MuiThemeProvider>
  </StyledEngineProvider>
)
