import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { FC } from 'react'

interface Props extends ButtonProps {
  loading?: boolean
}

export const LoadingButton: FC<Props> = (props) => {
  const { children, loading, ...rest } = props
  return <Button {...rest}>{loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : children}</Button>
}
