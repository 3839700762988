import { ActiveTime } from '../types/ActiveTime'

export const sanitizeActiveTimes = (activeTimes: ActiveTime[]): ActiveTime[] => {
  const sortedActiveTimes = activeTimes.sort((a, b) => {
    if (a.isoWeekday === b.isoWeekday) {
      return a.from.localeCompare(b.from)
    }
    return a.isoWeekday - b.isoWeekday
  })

  const sanitizedActiveTimes: ActiveTime[] = []
  let currentActiveTime: ActiveTime | undefined = undefined

  for (const activeTime of sortedActiveTimes) {
    if (!currentActiveTime || currentActiveTime.isoWeekday !== activeTime.isoWeekday) {
      currentActiveTime = { ...activeTime }
      sanitizedActiveTimes.push(currentActiveTime)
    } else {
      const lastSanitizedActiveTime = sanitizedActiveTimes[sanitizedActiveTimes.length - 1]
      if (activeTime.from > lastSanitizedActiveTime.to) {
        currentActiveTime = { ...activeTime }
        sanitizedActiveTimes.push(currentActiveTime)
      } else if (activeTime.to > lastSanitizedActiveTime.to) {
        lastSanitizedActiveTime.to = activeTime.to
      }
    }
  }

  return sanitizedActiveTimes
}
