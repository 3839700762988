import { FC } from 'react'
import { useWarehouseFromRoute } from '../Warehouse/hooks/useWarehouseFromRoute'
import { WarehouseSettingsView } from './WarehouseSettingsView'

export const WarehouseSettingsPage: FC = () => {
  const warehouse = useWarehouseFromRoute()

  if (!warehouse) return null

  return <WarehouseSettingsView key={warehouse.id} warehouse={warehouse} />
}
