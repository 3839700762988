import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField } from '@mui/material'
import { ThrottlingConfig } from '@quickcommerceltd/zone'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import shortid from 'shortid'
import ColorPicker from '../../ColorPicker/ColorPicker'
import IconPicker from '../../IconPicker/IconPicker'
import { WarehouseActiveTimesView } from '../../Warehouse/components/WarehouseActiveTimesView'
import { DEFAULT_DELIVERY_TIME } from '../../Warehouse/defaults'
import { useDeleteThrottlingConfig } from '../hooks/useDeleteThrottlingConfig'
import { useUpdateThrottlingConfig } from '../hooks/useUpdateThrottlingConfig'

interface Props {
  warehouseId: string
  onClose: () => void
  throttlingConfig?: ThrottlingConfig
}

export const StoreThrottlingForm: FC<Props> = (props) => {
  const [updateThrottlingConfig, isUpdatingThrottlingConfig] = useUpdateThrottlingConfig()
  const [deleteThrottlingConfig, isDeletingThrottlingConfig] = useDeleteThrottlingConfig()
  const isSubmitting = isUpdatingThrottlingConfig || isDeletingThrottlingConfig
  const throttlingConfigId = props.throttlingConfig?.id

  const [isMessageEnabled, setIsMessageEnabled] = useState(!!props.throttlingConfig?.message)
  const [isActiveTimesEnabled, setIsActiveTimesEnabled] = useState(!!props.throttlingConfig?.activeTimes)

  const defaultConfig: ThrottlingConfig = {
    id: shortid.generate(),
    stackSize: 10,
    enabled: true,
    deliveryTime: DEFAULT_DELIVERY_TIME,
    message: '',
    color: 'orange100',
    icon: 'info',
    closeTemporary: false,
    reopenStackSize: 5,
    activeTimes: null,
  }

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ...defaultConfig,
      ...props.throttlingConfig,
    },
    onSubmit: async (value) => {
      await updateThrottlingConfig({
        warehouseId: props.warehouseId,
        throttlingConfig: value,
      })
      props.onClose()
    },
  })

  const handleIsMessageEnabledChange = (event: any) => {
    setIsMessageEnabled(event.target.checked)
    if (!event.target.checked) {
      setFieldValue('message', '')
      setFieldValue('icon', 'info')
      setFieldValue('color', 'orange100')
    }
  }

  const handleIsActiveTimesEnabledChange = (event: any) => {
    setIsActiveTimesEnabled(event.target.checked)
    if (event.target.checked) {
      setFieldValue('activeTimes', [])
    } else {
      setFieldValue('activeTimes', null)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Orders stack size"
                variant="outlined"
                size="small"
                name="stackSize"
                value={values.stackSize}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                label="Close store"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.closeTemporary}
                    name="closeTemporary"
                    onChange={handleChange}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              {values.closeTemporary && (
                <TextField
                  fullWidth
                  type="number"
                  label="Reopen on stack size"
                  variant="outlined"
                  size="small"
                  name="reopenStackSize"
                  disabled={!values.closeTemporary}
                  value={values.reopenStackSize}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      return handleChange(e)
                    }

                    const nextReopenStackSize = Number(e.target.value)
                    setFieldValue('reopenStackSize', Math.min(nextReopenStackSize, values.stackSize - 1))
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                label="Show Message"
                control={
                  <Checkbox color="primary" checked={isMessageEnabled} onChange={handleIsMessageEnabledChange} />
                }
              />
            </Grid>
            <Grid item xs={6}>
              {isMessageEnabled && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <IconPicker
                      required
                      fullWidth
                      name="icon"
                      label="Icon"
                      value={values.icon}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      required
                      fullWidth
                      name="color"
                      label="Color"
                      value={values.color}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {isMessageEnabled && (
              <Grid item xs={12}>
                <TextField
                  required
                  size="small"
                  multiline
                  name="message"
                  label="Message"
                  fullWidth
                  variant="outlined"
                  value={values.message}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label="Scheduled"
                control={
                  <Checkbox
                    color="primary"
                    checked={isActiveTimesEnabled}
                    onChange={handleIsActiveTimesEnabledChange}
                  />
                }
              />
            </Grid>
            {isActiveTimesEnabled && (
              <Grid item xs={12}>
                <WarehouseActiveTimesView
                  activeTimes={values.activeTimes}
                  updateActiveTimes={(activeTimes) => setFieldValue('activeTimes', activeTimes)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
          {throttlingConfigId && (
            <Box mx={2}>
              <IconButton
                color="primary"
                disabled={isSubmitting}
                onClick={() =>
                  deleteThrottlingConfig({
                    throttlingConfigId,
                    warehouseId: props.warehouseId,
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            {props.throttlingConfig ? 'Save' : 'Create'}
          </Button>
        </Box>
      </Card>
    </form>
  )
}
