import { Badge } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const ChipIsDC = (props: { nav?: boolean }) => {
  const { classes } = useStyles()
  return <Badge className={props.nav ? classes.nav : classes.header}>DC</Badge>
}

export default ChipIsDC

const useStyles = makeStyles()(() => ({
  header: {
    color: '#02BAFF',
    fontWeight: 'bold',
    fontSize: '0.4em',
    marginLeft: '2px',
    marginBottom: '2px',
    border: '2px solid #02BAFF',
    borderRadius: '8px',
    padding: '0px 4px',
  },
  nav: {
    color: '#02BAFF',
    fontWeight: 'bold',
    fontSize: '0.6rem',
    marginLeft: '-2px',
  },
}))
