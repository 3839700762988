import { colors } from '@mui/material'
import { WarehouseStatus } from '@quickcommerceltd/zone'

export function getWarehouseStatusColor(warehouseStatus: WarehouseStatus): string {
  switch (warehouseStatus) {
    case 'DRAFT':
      return colors.grey[500]
    case 'CLOSED':
    case 'TEMPORARILY_CLOSED':
      return colors.red[500]
    case 'OPEN':
      return colors.green[500]
  }
}
