import { AppBar, Toolbar } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { SIDEBAR_WIDTH } from './AppSidebarView'
import { UserDropdown } from './UserDropdown'

const AppTopBar: FC = (props) => {
  const { classes } = useStyles()

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {props.children}
        <UserDropdown />
      </Toolbar>
    </AppBar>
  )
}

export default AppTopBar

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: theme.palette.grey[100],
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    marginLeft: SIDEBAR_WIDTH,
  },
  toolbar: {
    minHeight: 64,
  },
  content: {
    flexGrow: 1,
    marginLeft: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))
