import { WarehouseActiveTime } from '@quickcommerceltd/zone'
import { DateTime } from 'luxon'

const TIMEZONE = 'Europe/London'

export const isCurrentlyActive = (activeTimes: WarehouseActiveTime[] | null | undefined): boolean => {
  if (!activeTimes) return true // default: active
  if (!activeTimes.length) return false // no activeTimes -> not active

  const now = DateTime.now().setZone(TIMEZONE)
  return !!activeTimes.find((activeTime) => {
    if (now.weekday !== activeTime.isoWeekday) return false

    const from = DateTime.fromFormat(activeTime.from, 'HH:mm', {
      zone: TIMEZONE,
    })

    const to = DateTime.fromFormat(activeTime.to, 'HH:mm', {
      zone: TIMEZONE,
    })

    return now >= from && now < to
  })
}
