import { parseStringPromise } from 'xml2js'

export interface NamedPolygon {
  name: string
  points: { lat: number; lng: number }[]
}

interface Placemark {
  name: string[][]
  Polygon: {
    outerBoundaryIs: {
      LinearRing: {
        coordinates: string[][]
      }[]
    }[]
  }[]
}

export async function kmlToJson(kmlStr: string | ArrayBuffer): Promise<NamedPolygon[]> {
  const result = await parseStringPromise(kmlStr, {
    valueProcessors: [
      (value) =>
        value
          .trim()
          .split('\n')
          .map((v) => v.trim()),
    ],
  }).catch((err) => {
    throw err
  })

  // we want to extract the KML data as an array of polygons,
  // each polygon being an object with a name and list of lat / lng points
  const placemarks = result.kml.Document[0].Placemark as Placemark[]
  const namedPolygons = placemarks.flatMap((placemark) =>
    placemark.Polygon.flatMap((p) => p.outerBoundaryIs)
      .flatMap((outerBoundary) => outerBoundary.LinearRing)
      .map((linearRing) => {
        const latLng = linearRing.coordinates
          .flatMap((coordinate) => coordinate)
          .map((coordinate) => {
            const [lng, lat] = coordinate.split(',').map(Number)
            return { lat, lng }
          })
        return { name: placemark.name[0][0], points: latLng }
      })
  )

  return namedPolygons
}
