import { Box, Button, Typography } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { FC, useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Dialog, LoadingButton } from '../../Common/components'
import { toggleContingencyModeCallable } from '../callables/toogleContingencyMode'

interface Props {
  warehouse: Warehouse
  onClose: () => void
}

export const WarehouseDisableContingencyModeDialog: FC<Props> = ({ warehouse, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    await toggleContingencyModeCallable({
      warehouseId: warehouse.id,
      enable: false,
    })
      .then(() => toast.success('Store opened successfully'))
      .catch((error) => toast.error(error.message))
      .finally(() => {
        setIsSubmitting(false)
        onClose()
      })
  }, [warehouse.id, onClose])

  return (
    <Dialog open={true} onClose={onClose} title="Confirm Store Opening">
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        {warehouse.name} ({warehouse.shortName})
      </Typography>

      <Box my={3}>
        <Typography variant="body1">
          The store will be opened and will operate as usual. Stores that provided contingency polygons will be reset to
          their default polygons.
        </Typography>
      </Box>

      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        color="primary"
        size="large"
        disableElevation
        style={{ textTransform: 'none', color: 'white' }}
        onClick={handleSubmit}
        fullWidth
        disabled={isSubmitting}
      >
        Confirm
      </LoadingButton>
      <Button variant="text" color="primary" size="large" onClick={onClose} fullWidth>
        Cancel
      </Button>
    </Dialog>
  )
}
