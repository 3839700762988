import { WarehousePolygon } from '@quickcommerceltd/zone'
import { FirestoreError } from 'firebase/firestore'
import React, { FC, useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getWarehousePolygonCollectionRef } from '../../firebase/helpers/getCollectionRef'

type PolygonsValue = [WarehousePolygon[] | undefined, boolean, FirestoreError | undefined]

const defaultValue: PolygonsValue = [[], false, undefined]

const PolygonsContext = React.createContext(defaultValue)

export const usePolygons = () => {
  const value = useContext(PolygonsContext)
  return value
}

const PolygonsProvider: FC = ({ children }) => {
  const value = useCollectionData<WarehousePolygon>(getWarehousePolygonCollectionRef())

  return <PolygonsContext.Provider value={value}>{children}</PolygonsContext.Provider>
}

export default PolygonsProvider
