import { Stack, Tooltip, styled } from '@mui/material'
import { Warehouse, WarehouseStatus } from '@quickcommerceltd/zone'
import { FC, useMemo } from 'react'

interface Props {
  warehouse: Warehouse
}

export const WarehouseStatusIndicator: FC<Props> = ({ warehouse }) => {
  const storefronts = useMemo(() => {
    return Object.entries({
      STORE: { status: warehouse.status },
      ...warehouse.storefronts,
    })
      .sort(([providerA], [providerB]) => {
        if ([providerA, providerB].includes('STORE')) return 0
        return providerA.localeCompare(providerB)
      })
      .map(([provider, data]) => ({
        provider,
        status: data.status as WarehouseStatus,
      }))
  }, [warehouse])

  return (
    <Stack direction="row" spacing={0.5}>
      {storefronts.map(({ provider, status }) => (
        <Tooltip key={provider} title={`${provider} is ${status}`}>
          <StatusTile src={`/icons/${provider}.svg`} status={status} />
        </Tooltip>
      ))}
    </Stack>
  )
}

const StatusTile = styled('img')<{ status: WarehouseStatus }>(({ status }) => ({
  width: 20,
  height: 20,
  padding: 1,
  borderRadius: 2,
  objectFit: 'contain',

  ...(status === 'OPEN' && {
    backgroundColor: '#00805C',
  }),

  ...(['CLOSED', 'TEMPORARILY_CLOSED', 'DRAFT'].includes(status) && {
    backgroundColor: '#990F0F',
  }),
}))
