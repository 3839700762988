import { FC } from 'react'
import { useWarehouseFromRoute } from '../Warehouse/hooks/useWarehouseFromRoute'
import { WarehouseMessagesView } from './WarehouseMessagesView'

export const WarehouseMessagesPage: FC = () => {
  const warehouse = useWarehouseFromRoute()

  if (!warehouse) return null

  return <WarehouseMessagesView warehouse={warehouse} />
}
