import { WarehousePathPoint } from '@quickcommerceltd/zone'

export function getResettedWarehousePathPoints(latitude: number, longitude: number): WarehousePathPoint[] {
  return [
    [-1, -1],
    [-1, 1],
    [1, 1],
    [1, -1],
  ].map((value) => ({
    lat: latitude + value[0] * 0.0005,
    lng: longitude + value[1] * 0.0008,
  }))
}
