import { Box, CssBaseline } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AppRouter } from './AppRouter'
import AppSidebarView, { SIDEBAR_WIDTH } from './AppSidebarView'

const AppView: FC = () => {
  const { classes } = useStyles()

  return (
    <Box flex={1}>
      <CssBaseline />
      <AppSidebarView />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AppRouter />
      </main>
    </Box>
  )
}

export default AppView

const useStyles = makeStyles()(() => ({
  toolbar: {
    minHeight: 64,
  },
  content: {
    flexGrow: 1,
    marginLeft: SIDEBAR_WIDTH,
  },
}))
