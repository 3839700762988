import { Box, Button, Typography, Stack } from '@mui/material'
import { WarehousePath } from '@quickcommerceltd/zone'
import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { NamedPolygon, kmlToJson } from './helpers/kmlToJson'

interface Props {
  onUpload: (kmlPoints: WarehousePath['points']) => void
  isDisabled: boolean
}

const UploadKml: React.FC<Props> = ({ onUpload, isDisabled }) => {
  const [polygons, setPolygons] = useState<NamedPolygon[]>([])
  const inputEl = useRef<HTMLInputElement>(null)

  const handleUploadEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return

    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = async (evt: ProgressEvent<FileReader>) => {
      const fileStr: string | ArrayBuffer = evt?.target?.result || ''

      try {
        const namedPolygons = await kmlToJson(fileStr)
        setPolygons(namedPolygons)
      } catch (err) {
        toast.error(`Could not process KML file: ${(err as Error).message}`)
      }

      // clear the input element to allow re-uploading
      if (inputEl.current) {
        inputEl.current.value = ''
      }
    }

    reader.onerror = () => toast.error('Could not read KML file')
    reader.readAsText(file)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Button variant="contained" color="primary" component="label" disabled={isDisabled}>
        Import KML
        <input ref={inputEl} hidden accept=".kml" onChange={(e) => handleUploadEvent(e)} type="file" />
      </Button>

      {polygons.length > 0 && (
        <Typography sx={{ marginTop: '0.5em' }} gutterBottom>
          Select a polygon from KML:
        </Typography>
      )}

      <Stack direction="row" flexWrap="wrap" useFlexGap>
        {polygons.map((polygon) => (
          <Button
            key={polygon.name}
            sx={{ marginRight: '4px', marginTop: '4px' }}
            variant="outlined"
            onClick={() => onUpload(polygon.points)}
          >
            {polygon.name}
          </Button>
        ))}
      </Stack>
    </Box>
  )
}

export default UploadKml
