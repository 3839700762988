import { useParams } from 'react-router-dom'
import { LogsView } from './LogsView'

export function LogsPage() {
  const { warehouseId } = useParams<'warehouseId'>()

  if (!warehouseId) return null

  return <LogsView key={warehouseId} warehouseId={warehouseId} />
}
