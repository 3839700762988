import { WarehousePolygon } from '@quickcommerceltd/zone'
import { getWarehousePath } from './getWarehousePath'

export function jsonToKml(polygons: WarehousePolygon[], isoWeekday: number, minute: number): string {
  const kml = `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2"
    xmlns:gx="http://www.google.com/kml/ext/2.2"
    xmlns:kml="http://www.opengis.net/kml/2.2"
    xmlns:atom="http://www.w3.org/2005/Atom">
    <Document>
        ${polygons
          .map((polygon) => {
            const path = getWarehousePath(polygon, isoWeekday, minute)
            if (!path) return ''

            const coordinates = path.points.map(({ lat, lng }) => `${lng},${lat}`).join('\n')

            return `<Placemark>
                <name>${polygon.id}</name>
                <Polygon>
                    <outerBoundaryIs>
                        <LinearRing>
                            <coordinates>
                                ${coordinates}
                            </coordinates>
                        </LinearRing>
                    </outerBoundaryIs>
                </Polygon>
            </Placemark>`
          })
          .join('\n')}
    </Document>
</kml>`

  return kml
}
