import { Box, Chip, Divider, Grid, Typography } from '@mui/material'
import { Log } from '@quickcommerceltd/zone'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { deepReplaceTimestamp } from '../helpers/deepReplaceTimestamp'

interface Props {
  title?: string
  log?: Log
}

export const LogItemView: FC<Props> = (props) => {
  const logJsonString = useMemo(() => JSON.stringify(deepReplaceTimestamp(props.log?.update), null, 4), [props.log])

  if (!props.log) return null

  return (
    <Grid item sm={12} md={4}>
      <Box borderLeft="1px solid lightGray">
        <Box ml={2} mb={1}>
          <Typography variant="h6">{props.title || 'Store updated'}</Typography>
          <Typography>
            {DateTime.fromMillis(props.log.createdAt.toMillis()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Box m={2} flexDirection="row">
            <Chip style={{ marginRight: 10 }} label={`TYPE: ${props.log.updatedBy.type}`} />
            <Chip label={`NAME: ${props.log.updatedBy.name.split('@')[0]}`} />
            <pre>{logJsonString}</pre>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
