import { Warehouse } from '@quickcommerceltd/zone'

export type WarehouseStatus = 'DRAFT' | 'CLOSED' | 'OPEN'

export function getWarehouseStatus(warehouse: Partial<Warehouse>): WarehouseStatus {
  if (warehouse.isDraft) {
    return 'DRAFT'
  }

  if (warehouse.isTemporarilyClosed) {
    return 'CLOSED'
  }

  return 'OPEN'
}
