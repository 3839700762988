import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import StoreIcon from '@mui/icons-material/Store'
import StorefrontIcon from '@mui/icons-material/Storefront'
import SyncDisabledOutlinedIcon from '@mui/icons-material/SyncDisabledOutlined'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { colors } from '@mui/material'
import { Log } from '@quickcommerceltd/zone'
import { FC } from 'react'
import { getLogListItemProps } from '../helpers/getLogListItemProps'
import { LogListItem } from './LogListItem'

interface Props {
  log: Log
  isFirstItem: boolean
  isLastItem: boolean
  onClick: (title: string) => void
}

export const LogListItemView: FC<Props> = (props) => {
  const restProps = getLogListItemProps(props)

  if ('isInContingencyMode' in props.log.update) {
    if (props.log.update.isInContingencyMode) {
      return (
        <LogListItem
          {...restProps}
          title={['Enabled Contingency Mode', props.log.update.inContingencyModeFor].filter(Boolean).join(': ')}
          iconColor={colors.red[500]}
          iconComponent={BeachAccessIcon}
        />
      )
    }

    return (
      <LogListItem
        {...restProps}
        title="Disabled Contingency Mode"
        iconColor={colors.green[500]}
        iconComponent={BeachAccessIcon}
      />
    )
  }

  if ('status' in props.log.update) {
    switch (props.log.update.status) {
      case 'OPEN':
        return (
          <LogListItem
            {...restProps}
            title="Warehouse opened"
            iconColor={colors.green[500]}
            iconComponent={CheckCircleIcon}
          />
        )
      case 'TEMPORARILY_CLOSED':
        return (
          <LogListItem
            {...restProps}
            title="Warehouse Temporarily closed"
            iconColor={colors.orange[700]}
            iconComponent={CancelIcon}
          />
        )
      case 'DRAFT':
      case 'CLOSED':
        return (
          <LogListItem {...restProps} title="Warehouse closed" iconColor={colors.red[500]} iconComponent={CancelIcon} />
        )
    }
  }

  if ('storefronts' in props.log.update) {
    return (
      <LogListItem
        {...restProps}
        title="Storefront updated"
        iconColor={colors.blue[500]}
        iconComponent={StorefrontIcon}
      />
    )
  }

  if ('pickUpAddress' in props.log.update) {
    return (
      <LogListItem
        {...restProps}
        title="Warehouse Address updated"
        iconColor={colors.blue[500]}
        iconComponent={StoreIcon}
      />
    )
  }

  if ('paths' in props.log.update) {
    return (
      <LogListItem
        {...restProps}
        title="Warehouse Polygon updated"
        iconColor={colors.deepPurple[500]}
        iconComponent={LocationCityIcon}
      />
    )
  }

  if ('warning' in props.log.update) {
    return (
      <LogListItem
        {...restProps}
        title="Warehouse Warning updated"
        iconColor={colors.orange[500]}
        iconComponent={InfoIcon}
      />
    )
  }

  if (props.log.collectionName === 'throttlingConfigs') {
    if (!props.log.update.message) {
      return (
        <LogListItem
          {...restProps}
          title="Throttling Config removed"
          iconColor={colors.red[500]}
          iconComponent={SyncDisabledOutlinedIcon}
        />
      )
    }

    return (
      <LogListItem
        {...restProps}
        title="Throttling Config updated"
        iconColor={colors.cyan[500]}
        iconComponent={SyncProblemIcon}
      />
    )
  }

  if (props.log.collectionName === 'storeWarnings') {
    if (!props.log.update.message) {
      return (
        <LogListItem
          {...restProps}
          title="Store Warning removed"
          iconColor={colors.red[500]}
          iconComponent={SyncDisabledOutlinedIcon}
        />
      )
    }

    return (
      <LogListItem
        {...restProps}
        title="Store Warning updated"
        iconColor={colors.cyan[500]}
        iconComponent={SyncProblemIcon}
      />
    )
  }

  if (props.log.collectionName === 'warehousePolygons') {
    if (props.log.update.activePathId) {
      return (
        <LogListItem
          {...restProps}
          title="Active Polygon changed"
          iconColor={colors.cyan[500]}
          iconComponent={SyncProblemIcon}
        />
      )
    }
  }

  if ('isAutomaticThrottlingEnabled' in props.log.update) {
    if (props.log.update.isAutomaticThrottlingEnabled) {
      return (
        <LogListItem
          {...restProps}
          title="Enabled Automatic Throttling"
          iconColor={colors.green[500]}
          iconComponent={SyncOutlinedIcon}
        />
      )
    }

    return (
      <LogListItem
        {...restProps}
        title="Disabled Automatic Throttling"
        iconColor={colors.red[500]}
        iconComponent={SyncDisabledOutlinedIcon}
      />
    )
  }

  return (
    <LogListItem
      {...restProps}
      title="Warehouse updated"
      iconColor={colors.grey[500]}
      iconComponent={TrackChangesIcon}
    />
  )
}
