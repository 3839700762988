import { Button, Card, CardContent } from '@mui/material'
import { User } from 'firebase/auth'
import { FC, createContext, useContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import firebase, { auth } from '../../firebase'
import { getUserClaimsDocRef } from '../../firebase/helpers/getDocRef'

interface AuthProviderValue {
  user: User | null | undefined
  claims?: {
    admin: boolean | undefined
    manager: boolean | undefined
  }
}

const defaultAuthProviderValue: AuthProviderValue = {
  user: null,
  claims: undefined,
}

const AuthContext = createContext<AuthProviderValue>(defaultAuthProviderValue)

export let googleProvider = new firebase.auth.GoogleAuthProvider()

export const AuthProvider: FC = (props) => {
  const [user, isLoading] = useAuthState(auth)

  const [claims] = useDocumentData(user?.email ? getUserClaimsDocRef(user.email) : null)

  const value: AuthProviderValue = {
    user,
    claims,
  }

  function renderSignInView() {
    return (
      <Card>
        <CardContent>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              firebase
                .auth()
                .signInWithRedirect(googleProvider)
                .catch(() => {})
            }}
          >
            Google Signin
          </Button>
        </CardContent>
      </Card>
    )
  }

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? null : user ? props.children : renderSignInView()}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
