import { Box, Card, Typography } from '@mui/material'
import { StoreWarning } from '@quickcommerceltd/zone'
import cx from 'classnames'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { warningColors, warningIcons } from './WarningIcons'

interface Props {
  value: StoreWarning
}

const useStyles = makeStyles()(() => ({
  root: { width: '100%' },
  icon: { '& > svg': { display: 'block' } },
}))

export const StoreWarningView: FC<Props> = ({ value }) => {
  const { classes: styles } = useStyles()
  const color = value.color || 'orange100'
  const iconName = value.icon || 'clock'
  const Icon = warningIcons[iconName] || null

  return (
    <Card elevation={0} className={cx(styles.root)} style={{ backgroundColor: warningColors[color] }}>
      <Box display="flex" p={2}>
        <Box mr={2} className={styles.icon}>
          <Icon width={28} height={28} />
        </Box>
        <Box flex={1}>
          <Typography variant="body1">{value.message}</Typography>
        </Box>
      </Box>
    </Card>
  )
}
