import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { FC } from 'react'
import { warningIcons } from '../Warehouse/components/WarningIcons'

const IconPicker: FC<SelectProps> = (props) => {
  return (
    <FormControl variant="outlined" size="small" fullWidth={props.fullWidth}>
      <InputLabel>Icon</InputLabel>
      <Select {...props}>
        {Object.keys(warningIcons).map((name) => {
          const Icon = warningIcons[name]
          return (
            <MenuItem key={name} value={name} style={{ height: 40 }}>
              <Icon width={14} height={14} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
export default IconPicker
