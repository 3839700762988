import { Log } from '@quickcommerceltd/zone'
import { RelativeTimestampView } from '../components/RelativeTimestampView'

interface Props {
  log: Log
  isFirstItem: boolean
  isLastItem: boolean
  onClick: (title: string) => void
}

export function getLogListItemProps(props: Props) {
  return {
    createdAt: props.log.createdAt,
    createdByName: props.log.updatedBy.name.split('@')[0],
    message: (
      <RelativeTimestampView
        timestamp={props.log.createdAt}
        translation={`{diff} ago by ${props.log.updatedBy.name.split('@')[0]}`}
      />
    ),
    isFirstItem: props.isFirstItem,
    isLastItem: props.isLastItem,
    onClick: props.onClick,
  }
}
