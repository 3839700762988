import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, CircularProgress, Grid, Switch, Tooltip, Typography } from '@mui/material'
import { StoreWarning, ThrottlingConfig, Warehouse } from '@quickcommerceltd/zone'
import { limit, query } from 'firebase/firestore'
import orderBy from 'lodash/orderBy'
import { FC, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useSearchParams } from 'react-router-dom'
import {
  getStoreWarningCollectionRef,
  getWarehouseThrottlingConfigCollectionRef,
} from '../../firebase/helpers/getCollectionRef'
import { useAuth } from '../Auth/AuthProvider'
import { StoreThrottlingForm } from './components/StoreThrottlingForm'
import { StoreThrottlingItem } from './components/StoreThrottlingItem'
import { StoreWarningForm } from './components/StoreWarningForm'
import { StoreWarningItem } from './components/StoreWarningItem'
import { useUpdateAutomaticThrottling } from './hooks/useUpdateAutomaticThrottling'

interface Props {
  warehouse: Warehouse
}

const isTruthy = (value: string | null) => {
  if (!value) return false
  const falsyValues = ['0', 'false', '', 'undefined', 'null', 'Nan']
  return !falsyValues.includes(value.toLowerCase())
}

export const WarehouseMessagesView: FC<Props> = (props: Props) => {
  const [updateAutomaticThrottling, isUpdatingAutomaticThrottling] = useUpdateAutomaticThrottling()
  const auth = useAuth()
  const { warehouse } = props
  const isAdmin = auth.claims?.admin
  const canToggle = isAdmin || auth.claims?.manager

  const [isCreatingCustomMessage, setCreatingCustomMessage] = useState(false)
  const [isCreatingThrottlingForm, setCreatingThrottlingForm] = useState(false)

  const [throttlingConfigs, isLoadingThrottlingConfigs] = useCollectionData<ThrottlingConfig>(
    query(getWarehouseThrottlingConfigCollectionRef(warehouse.id), limit(100))
  )

  const [storeWarnings, isLoadingStoreWarnings] = useCollectionData<StoreWarning>(
    query(getStoreWarningCollectionRef(warehouse.id), limit(100))
  )
  const [urlParams] = useSearchParams()
  const showCustomMessagesParam = urlParams.get('showCustomMessages')
  const showCustomMessages = isTruthy(showCustomMessagesParam)

  if (isLoadingThrottlingConfigs || isLoadingStoreWarnings) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={4}>
      {showCustomMessages && (
        <Grid item sm={12} md={6}>
          <Box minHeight={40} mb={1}>
            <Typography variant="h6">
              Store custom messages{' '}
              <Tooltip arrow title="When enabling a message the automated throttling will be disabled">
                <InfoIcon />
              </Tooltip>
            </Typography>
          </Box>

          <Button
            fullWidth
            variant="outlined"
            disabled={!isAdmin}
            onClick={() => setCreatingCustomMessage(!isCreatingCustomMessage)}
          >
            Add template
          </Button>

          <Box mb={2} />

          {isCreatingCustomMessage && (
            <StoreWarningForm warehouseId={warehouse.id} onClose={() => setCreatingCustomMessage(false)} />
          )}

          <Box mb={2} />

          {(storeWarnings || []).map((warning: StoreWarning) => (
            <StoreWarningItem
              key={warning.id}
              storeWarning={warning}
              warehouseId={warehouse.id}
              isActiveNow={warehouse.warning?.id === warning.id}
            />
          ))}
        </Grid>
      )}

      <Grid item sm={12} md={6}>
        <Box minHeight={40} mb={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              Automated throttling{' '}
              <Tooltip arrow title="Enabling this setting will not open manually closed store">
                <InfoIcon />
              </Tooltip>
            </Typography>
            <Switch
              disabled={!canToggle || isUpdatingAutomaticThrottling}
              color="primary"
              name="isAutomaticThrottlingEnabled"
              checked={warehouse.isAutomaticThrottlingEnabled ?? false}
              onChange={(e) =>
                updateAutomaticThrottling({
                  warehouseId: warehouse.id,
                  enabled: e.target.checked,
                })
              }
            />
          </Box>
        </Box>

        <Button
          fullWidth
          variant="outlined"
          disabled={!isAdmin}
          onClick={() => setCreatingThrottlingForm(!isCreatingThrottlingForm)}
        >
          Add template
        </Button>

        {isCreatingThrottlingForm && (
          <StoreThrottlingForm warehouseId={warehouse.id} onClose={() => setCreatingThrottlingForm(false)} />
        )}

        <Box mb={2} />

        {orderBy(throttlingConfigs || [], 'stackSize').map((config) => (
          <StoreThrottlingItem key={config.id} warehouseId={warehouse.id} throttlingConfig={config} />
        ))}
      </Grid>
    </Grid>
  )
}
