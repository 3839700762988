import { Grid } from '@mui/material'
import { Log } from '@quickcommerceltd/zone'
import { useState } from 'react'
import { LogItemView } from './components/LogItemView'
import { LogListView } from './components/LogListView'

interface Props {
  warehouseId: string
}

export function LogsView(props: Props) {
  const [logItemTitle, setLogItemTitle] = useState<string>()
  const [logItem, setLogItem] = useState<Log>()

  return (
    <Grid container>
      <LogListView
        warehouseId={props.warehouseId}
        onLogClick={(title: string, log: Log) => {
          setLogItemTitle(title)
          setLogItem(log)
        }}
      />
      <LogItemView title={logItemTitle} log={logItem} />
    </Grid>
  )
}
