import { Log, StoreWarning, ThrottlingConfig, Warehouse, WarehousePolygon } from '@quickcommerceltd/zone'
import { collection, CollectionReference } from 'firebase/firestore'
import { UserClaim } from '../../Components/Auth/Authorize'
import { CollectionName } from '../CollectionName'
import { db } from '../firebase'

function getCollectionRef<T>(collectionName: CollectionName) {
  return collection(db, collectionName) as CollectionReference<T>
}

export function getUserClaimCollectionRef() {
  return getCollectionRef<UserClaim>('user_claims')
}

export function getWarehouseCollectionRef() {
  return getCollectionRef<Warehouse>('warehouses')
}

export function getWarehousePolygonCollectionRef() {
  return getCollectionRef<WarehousePolygon>('warehousePolygons')
}

export function getWarehouseLogCollectionRef(warehouseId: string) {
  return getCollectionRef<Log>(`warehouses/${warehouseId}/logs`)
}

export function getWarehouseThrottlingConfigCollectionRef(warehouseId: string) {
  return getCollectionRef<ThrottlingConfig>(`warehouses/${warehouseId}/throttlingConfigs`)
}

export function getStoreWarningCollectionRef(warehouseId: string) {
  return getCollectionRef<StoreWarning>(`warehouses/${warehouseId}/storeWarnings`)
}
